<template>
  <v-menu top>
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">

          <v-btn
            v-if="!showButtons"
            :disabled="selected.length === 0"
            class="mx-2"
            color="primary"
            dark
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            {{ $t("Actions") }}
          </v-btn>
          <div v-else class="">
            <template v-for="(th,index) in headers">

              <action-dialog
                v-if="$can('edit_' + th.value,config.resource)"
                :key="index"  :options="{}"
                             :th="th"
                             :selected="selected"
                             :showButtons="showButtons"
                             @saved="$emit('refetch-data')"
                             :config="config" />
            </template>
          </div>
        </template>
        <span>{{ $t("Actions sur plusieurs")}}</span>
      </v-tooltip>
    </template>

    <v-list  style="max-height: 400px; overflow-y: auto">

      <template v-for="(th,index) in headers">

        <action-dialog :key="index"  :options="{}"  v-if="$can('edit_' + th.value,config.resource)"
                       :th="th"
                       :selected="selected"
                       @saved="$emit('refetch-data')"
                       :config="config" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import { avatarText, formatDate } from '@core/utils/filter'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import ActionDialog from '@/components/ActionDialog'
export default {
  components: { ActionDialog, DateRangePicker, DatePicker },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({ url: '/' }),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const items = computed({
      get: () => props.headers.map(ele => {
        ele.menu = ele.menu || false

        return ele
      }),
      set: value => emit('update:object', value),
    }, { deep: true })

    return {
      formatDate,
      items,
      today,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
      },
    }
  },
}
</script>
