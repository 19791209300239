<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Attribuer le dossier") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-divider />
      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <v-col cols="12">
            <div class="d-flex align-center" v-if="item.author && item.author.id">
              <v-avatar
                :color="item.author.avatar ? '' : 'primary'"
                :class="item.author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="30"
              >
                <v-img
                  v-if="item.author.avatar"
                  :lazy-src="require(`@/assets/images/avatars/2.png`)"
                  :src="item.author.avatar || require(`@/assets/images/avatars/2.png`)"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(item.author.full_name) }}</span>
              </v-avatar>

              <div class="d-flex flex-column ms-3">
                <router-link
                  :to="{ name : 'author-view', params : { id : item.author_id } }"
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.author.full_name }}
                </router-link>
                <small>{{ item.manuscript.title }}</small>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="item.auto"
              :label="$t('Attribution manuelle')"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="item.gestion_id"
              :disabled="!item.auto"
              :label="$t('Gestionnaire')"
              :error-messages="errorMessages.gestion"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="users.filter(el => el.user_id == null && el.role_name === 'gestionnaire')"
              no-data-text="Aucun élément disponible"
            >
              <template #item="{ item }">
                <div>
                  {{ item.name }} - {{ item.code }} <span class="float-right">{{ item.nb_tasks }}</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="item.assistant_id"
              :disabled="!item.auto"
              :label="$t('Assistant')"
              :error-messages="errorMessages.assistant"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="users.filter(el => el.user_id === item.gestion_id)"
              :loading="isLoadingUsers"

              :search-input.sync="search"
              no-data-text="Aucun élément disponible"
            >
              <template #item="{ item }">
                <div>
                  {{ item.name }} - {{ item.code }} <span class="float-right">{{ item.nb_tasks }}</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
<!--
          <v-col cols="12">
            <v-autocomplete
              v-model="item.assistant_id"
              :disabled="!item.auto"
              :label="$t('Assistant')"
              :error-messages="errorMessages.assistant"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="users.filter(el => el.user_id === item.gestion_id)"
              :loading="isLoadingUsers"

              :search-input.sync="search"
              no-data-text="Aucun élément disponible"
            >
            </v-autocomplete>
          </v-col>-->
          <v-col cols="12">
            <v-autocomplete
              v-model="item.assistant_pub_id"
              :disabled="!item.auto"
              :label="$t('Choisir l\'assistant publication')"
              :error-messages="errorMessages.assistant_pub_id"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              :items="users.filter(el => el.role_name === 'service_publications')"
              :loading="isLoadingUsers"

              no-data-text="Aucun élément disponible"
            >
              <template #item="{ item }">
                <div>
                  {{ item.name }} - {{ item.code }} <span class="float-right">{{ item.nb_tasks }}</span>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <date-picker v-model="item.task_date"  :label="$t('Date')" />
<!--            <v-dialog
              ref="dialogDate"
              v-model="modalDate"
              :disabled="!item.auto"
              :return-value.sync="item.task_date"
              persistent
              width="290px"
              :label="$t('Date')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.task_date"
                  :disabled="!item.auto"
                  :error-messages="errorMessages.task_date"
                  :label="$t('Date')"
                  :append-icon="icons.mdiCalendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.task_date"
                locale="fr"
                scrollable
                first-day-of-week="1"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalDate = false"
                >
                  {{ $t("Annuler") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogDate.save(item.task_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>-->
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="form-actions">
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Confirmer") }}
          </v-btn>
          <v-spacer />
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'

export default {
  components: { DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {
    } = authorsController()
    const users = ref([])
    const isLoadingUsers = ref(false)
    const errorMessages = ref({ gestion: null, assistant: null })
    const valid = ref(false)
    const modalDate = ref(false)
    const dialogDate = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPublications = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })

    const onSubmit = () => {
      if (valid.value) {
        if (item.value.auto && !item.value.gestion_id && !item.value.assistant_id) {
          snackBarMessage.value = 'Sélectionnez au moins un gestionnaire ou un assistant'
          isSnackbarBottomVisible.value = true
          errorMessages.value.gestion = 'Sélectionnez au moins un gestionnaire ou un assistant'
          errorMessages.value.assistant = 'Sélectionnez au moins un gestionnaire ou un assistant'
        } else {

        }
        loading.value = true
        const url = !item.value.auto ? '/automatic_task' : '/tasks'
        axios.post(url, {
          publication_id: item.value.publication_id, gestion_id: item.value.gestion_id, assistant_id: item.value.assistant_id, task_date: item.value.task_date,
        }).then(response => {
          loading.value = false
          if (response && (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false'))) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...response.data.errors }
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)

          emit('refetch-data')
        }).catch(error => {
          loading.value = false
          console.log('error', error)
          if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            errorMessages.value = { ...error.data.errors }
          }
        })
      } else {
        validate()
      }
    }
    const onSearch = val => {
      isLoadingUsers.value = true
      axios
        .get('/listWorkers', { params: { term: val, user: item.value.gestion_id, rowsPerPage: 100 } })
        .then(response => {
          isLoadingUsers.value = false
          for (let i = 0; i < response.data.length; i++) {
            const u = response.data[i]
            const indexU = users.value.findIndex(el => el.id === u.id)
            if (indexU < 0) {
              users.value.push(u)
            }
          }
        })
    }
    onSearch('')

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingUsers.value) return ''
      onSearch(val)

      return ''
    })

    return {
      form,
      isFormAuthorActive,
      isLoadingPublications,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      search,
      authors,
      isLoadingAuthors,
      isLoadingUsers,
      onSubmit,
      modalDate,
      dialogDate,
      item,
      users,
      valid,
      dialog,
      loading,
      validate,
      avatarText,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
