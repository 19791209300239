<template>
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-if="!showButtons"
          small
          dense
          depressed
          outlined
          style="min-width: 67px; max-width: 300px"
          v-bind="attrs"
          v-on="on"

        >
          <v-list-item-title>
            {{ th.text }}
          </v-list-item-title>
        </v-list-item>
        <v-btn
          v-else
          color="primary"
          class="ml-1 "
          :disabled="!selected.length"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-if="th.type === 'date'">{{ icons.mdiCalendar }}</v-icon>
          <span>{{ th.text }}</span>
        </v-btn>
      </template>
      <CellForm
        v-if="isFormActive"
        v-model="item"
        :options="th"
        :config="config"
        :is-form-active="isFormActive"
        :group="true"
        :selected="selected"
        @saved="saved"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Traitement de')  }} "{{th.text}}"</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </CellForm>
    </v-dialog>

</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
  mdiClose,
  mdiInformation,
  mdiCheckboxMarkedCircleOutline,
  mdiStar,
  mdiCheckCircle,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import CellForm from '@/components/CellForm'
import ability from '@/plugins/acl/ability'

export default {
  components: { CellForm },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({ url: '/' }),
    },
    th: {
      type: Object,
      default: () => {},
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const tooltip = ref(false)
    const isFormActive = ref(false)
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    }, { deep: true })

    const additionalClasses = ref({})
    const colors = ref([])
    const comments = ref([])
    const setComments = data => {
      if (data && data[props.th.comments] && data[props.th.comments].length) {
        comments.value = data[props.th.comments].filter(el => el[props.th.comment_reference || 'column_name'] === (props.th.comment_column || props.th.value) && el.comment && el.comment.trim().length)
      } else {
        comments.value = []
      }
    }
    setComments(item.value)
    const setColors = data => {
      if (data[props.th.colors] && data[props.th.colors].length) {
        colors.value = data[props.th.colors].filter(el => el[props.th.color_reference || 'column_name'] === (props.th.color_column || props.th.value) && el.color)
      } else {
        colors.value = []
      }
    }
    setColors(item.value)
    const setColor = () => {
      if (item.value[props.th.colors] && item.value[props.th.colors].length > 0) {
        const records = item.value[props.th.colors].filter(ele => ele[props.th.color_reference || 'column_name'] === (props.th.color_column || props.th.value))

        if (records && records.length) {
          item.value.color_id = records[0].color_id || null
          item.value.fore_color_id = records[0].fore_color_id || null
        }
      } else {
        item.value.color_id = null
      }
    }
    setColor()

    const executeFunctions = () => {
      if (props.th.functions && props.th.functions.length) {
        for (let i = 0; i < props.th.functions.length; i++) {
          (props.th.functions[i])(props.th, item.value)
        }
      }
    }
    executeFunctions()
    watch(item, val => {
      emit('update:object', val)
      setColors(val)
      setComments(val)
      executeFunctions()

      // setColor()
    }, { deep: true })

    const formatDateShort = (date, option) => formatDate(date, option)
    const canEdit = computed(
      {
        get: ()=> {
          if (props.th.editable && !(item.value.denieds && item.value.denieds[props.th.value])) {
            //if(props.th.editPermission) {
            //return ability.can(props.th.editPermission, 'Communication')
            return ability.can('edit_' + props.th.value, props.config.resource)
            //}
            //return true
          }
        }
      }
    )
    const edit = () => {
      if (canEdit.value) {
        item.value.color_id = null
        setColor()
        console.log('color', item.value.color_id)
        item.value.comment = null
        isFormActive.value = true
      }
    }
    const hover = () => {
      if (comments.value.length) {
        tooltip.value = true
      }
    }
    const leave = () => {
      tooltip.value = false
    }
    const saved = data => {
      emit('update:object', data)
      emit('saved', data)
      item.value = data
      setColors(data)
      setComments(data)
      setColor()
      executeFunctions()
      isFormActive.value = false
    }
    const setBackgroundColor = computed({
      get: () => {
        if (!colors) return {}

        if (colors.value.length > 0) {
          const records = colors.value.filter(ele => (props.th.color_column || props.th.value) === ele[props.th.color_reference || 'column_name'])

          if (!records.length || !records[0].color_id) return {}

          return {
            'background-color': records[0].color.bg_color,
            color: records[0].fore_color_id ? records[0].forecolor.bg_color : '#000000'/* records[0].color.txt_color */,
            height: '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
          }
        }
      },
    })

    return {
      formatDate,
      formatDateShort,
      additionalClasses,
      saved,
      canEdit,
      setBackgroundColor,
      item,
      isFormActive,
      edit,
      hover,
      leave,
      comments,
      colors,
      today,
      tooltip,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
        mdiInformation,
        mdiClose,
        mdiCheckboxMarkedCircleOutline,
        mdiStar,
        mdiCheckCircle,
      },
    }
  },
}
</script>
